.navbar {
    background-color: map-get($color-variants, "blue-500");

    .logo {
        width: 100%;
        padding: 51px 40px 23px 40px;
    }

    ul.menu {
        display: flex;
        flex-direction: column;
        align-items: end;
        gap: 8px;
        padding-left: 32px;
        
        li {
            width: 100%;

            &.is_active {
                border-right: 6px solid map-get($color-variants, "neutrals-white");
            }
        }

        > li a {
            @extend .text-bd1-smbd;
            padding: 14px 24px;
            display: flex;
            gap: 24px;
            align-items: center;
            color: map-get($color-variants, "neutrals-white");
        }
    }
}
