body {
  font-family: $font-family-dmsans;
  min-height: 100vh;
  display: flex;
  box-sizing: border-box;
  background-color: map-get($color-variants, "bg-light-grey");
  
  > * {
    flex: 1 1 100%;
  }

  &.is_login {
    background-color: map-get($color-variants, "bg-neutral-grey");
  }
}
