.card.card_module {
    @extend .bg-neutrals-white;
    box-shadow: 0px 20px 50px 0px #1a1a1a0d;

    border-radius: 12px;
    overflow: hidden;

    .card_thumbnail {
        > img {
            width: 100%;
            max-height: 163px;
            object-fit: cover;
            aspect-ratio: 275/173;
        }
    }

    .card_info {
        progress.card_progress {
            width: 100%;
            height: 6px;
            border: none;

            &::-webkit-progress-bar {
                @extend .bg-yellow-100;
                border-radius: 6px;
                height: 6px;
            }
            &::-webkit-progress-value {
                @extend .bg-yellow-500;
                border-radius: 6px;
                height: 6px;
            }
        }
    }
}

.card.info_card {
    @extend .bg-neutrals-white;
    border-radius: 12px;
    padding: 40px 24px;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 16px;
    box-shadow: 0px 20px 50px 0px #0000000d;

    .info_card-icon {
        @extend .bg-green-600;
        @extend .color-neutrals-white;

        padding: 16px;
        display: inline-block;
        border-radius: 12px;
    }
}

.card.card_component{
    &-app{
        @extend .bg-blue-100;
        width: 75px;
        border-radius: 100%;
        aspect-ratio: 1;
        
        display: flex;
        justify-content: center;
        align-items: center;
    }
}