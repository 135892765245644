$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "fonts" !default;

$icon-magnifier: unquote('"\\e91d"');
$icon-explore: unquote('"\\e91b"');
$icon-globe: unquote('"\\e91c"');
$icon-checkmark-alternate: unquote('"\\e900"');
$icon-checkmark--filled: unquote('"\\e901"');
$icon-chevron--down: unquote('"\\e902"');
$icon-chevron--up: unquote('"\\e903"');
$icon-document: unquote('"\\e904"');
$icon-edit: unquote('"\\e905"');
$icon-email: unquote('"\\e906"');
$icon-exit: unquote('"\\e907"');
$icon-favorite: unquote('"\\e908"');
$icon-favorite--filled: unquote('"\\e909"');
$icon-favorite--filledColor: unquote('"\\e90a"');
$icon-help: unquote('"\\e90b"');
$icon-help-desk: unquote('"\\e90c"');
$icon-home: unquote('"\\e90d"');
$icon-meter: unquote('"\\e90e"');
$icon-misuse: unquote('"\\e90f"');
$icon-phone: unquote('"\\e910"');
$icon-send-1: unquote('"\\e911"');
$icon-send--filled: unquote('"\\e912"');
$icon-skill-level-1: unquote('"\\e913"');
$icon-skill-level--advanced: unquote('"\\e914"');
$icon-time: unquote('"\\e915"');
$icon-trash-can: unquote('"\\e916"');
$icon-user: unquote('"\\e917"');
$icon-user--multiple: unquote('"\\e918"');
$icon-view: unquote('"\\e919"');
$icon-view-off-filled: unquote('"\\e91a"');

