.loading {
    position: fixed;
    inset: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    background-color: rgba(255, 255, 255, 0.8);
}
