@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?rpw780');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?rpw780#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?rpw780') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?rpw780') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?rpw780##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-magnifier {
  &:before {
    content: $icon-magnifier; 
  }
}
.icon-explore {
  &:before {
    content: $icon-explore; 
  }
}
.icon-globe {
  &:before {
    content: $icon-globe; 
  }
}
.icon-checkmark-alternate {
  &:before {
    content: $icon-checkmark-alternate; 
  }
}
.icon-checkmark--filled {
  &:before {
    content: $icon-checkmark--filled; 
  }
}
.icon-chevron--down {
  &:before {
    content: $icon-chevron--down; 
  }
}
.icon-chevron--up {
  &:before {
    content: $icon-chevron--up; 
  }
}
.icon-document {
  &:before {
    content: $icon-document; 
  }
}
.icon-edit {
  &:before {
    content: $icon-edit; 
  }
}
.icon-email {
  &:before {
    content: $icon-email; 
  }
}
.icon-exit {
  &:before {
    content: $icon-exit; 
  }
}
.icon-favorite {
  &:before {
    content: $icon-favorite; 
  }
}
.icon-favorite--filled {
  &:before {
    content: $icon-favorite--filled; 
  }
}
.icon-favorite--filledColor {
  &:before {
    content: $icon-favorite--filledColor;     
    color: #ca3c1b;
  }
}
.icon-help {
  &:before {
    content: $icon-help; 
  }
}
.icon-help-desk {
  &:before {
    content: $icon-help-desk; 
  }
}
.icon-home {
  &:before {
    content: $icon-home; 
  }
}
.icon-meter {
  &:before {
    content: $icon-meter; 
  }
}
.icon-misuse {
  &:before {
    content: $icon-misuse; 
  }
}
.icon-phone {
  &:before {
    content: $icon-phone; 
  }
}
.icon-send-1 {
  &:before {
    content: $icon-send-1; 
  }
}
.icon-send--filled {
  &:before {
    content: $icon-send--filled; 
  }
}
.icon-skill-level-1 {
  &:before {
    content: $icon-skill-level-1; 
  }
}
.icon-skill-level--advanced {
  &:before {
    content: $icon-skill-level--advanced; 
  }
}
.icon-time {
  &:before {
    content: $icon-time; 
  }
}
.icon-trash-can {
  &:before {
    content: $icon-trash-can; 
  }
}
.icon-user {
  &:before {
    content: $icon-user; 
  }
}
.icon-user--multiple {
  &:before {
    content: $icon-user--multiple; 
  }
}
.icon-view {
  &:before {
    content: $icon-view; 
  }
}
.icon-view-off-filled {
  &:before {
    content: $icon-view-off-filled; 
  }
}

