.layout {
    display: grid;
    min-height: 100%;
    grid-template-columns: max(calc(345 / 1920 * 100vw), 332px) auto;
    grid-template-rows: 140px auto;

    grid-template-areas:
        "navbar header"
        "navbar content";

    > .navbar {
        grid-area: navbar;
    }
    > .header {
        grid-area: header;
    }
    > .content {
        grid-area: content;
    }
}
